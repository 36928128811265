exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angielski-biznesowy-js": () => import("./../../../src/pages/angielski-biznesowy.js" /* webpackChunkName: "component---src-pages-angielski-biznesowy-js" */),
  "component---src-pages-angielski-branzowy-js": () => import("./../../../src/pages/angielski-branzowy.js" /* webpackChunkName: "component---src-pages-angielski-branzowy-js" */),
  "component---src-pages-angielski-dla-firm-js": () => import("./../../../src/pages/angielski-dla-firm.js" /* webpackChunkName: "component---src-pages-angielski-dla-firm-js" */),
  "component---src-pages-angielski-konwersacje-js": () => import("./../../../src/pages/angielski-konwersacje.js" /* webpackChunkName: "component---src-pages-angielski-konwersacje-js" */),
  "component---src-pages-angielski-ogolny-js": () => import("./../../../src/pages/angielski-ogolny.js" /* webpackChunkName: "component---src-pages-angielski-ogolny-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-certyfikaty-cambridge-js": () => import("./../../../src/pages/certyfikaty-cambridge.js" /* webpackChunkName: "component---src-pages-certyfikaty-cambridge-js" */),
  "component---src-pages-executive-english-js": () => import("./../../../src/pages/executive-english.js" /* webpackChunkName: "component---src-pages-executive-english-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kurs-maturalny-js": () => import("./../../../src/pages/kurs-maturalny.js" /* webpackChunkName: "component---src-pages-kurs-maturalny-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-rozmowa-o-prace-js": () => import("./../../../src/pages/rozmowa-o-prace.js" /* webpackChunkName: "component---src-pages-rozmowa-o-prace-js" */),
  "component---src-pages-studia-w-uk-i-usa-js": () => import("./../../../src/pages/studia-w-uk-i-usa.js" /* webpackChunkName: "component---src-pages-studia-w-uk-i-usa-js" */),
  "component---src-pages-tlumaczenia-js": () => import("./../../../src/pages/tlumaczenia.js" /* webpackChunkName: "component---src-pages-tlumaczenia-js" */),
  "component---src-pages-wspolpraca-js": () => import("./../../../src/pages/wspolpraca.js" /* webpackChunkName: "component---src-pages-wspolpraca-js" */)
}

